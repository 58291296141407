<template>
  <div class="equityorder">
    <frame :top1="top1" :top2="top2">
      <!-- 订单状态 -->
      <RadioGroup v-model="animal">
        <Radio
          v-for="(item, index) in tabbar"
          :label="item"
          border
          :key="index"
          @change="radioChange()"
        ></Radio>
      </RadioGroup>
      <!-- 搜索 -->
      <div class="content">
        <div class="liubai"><Icon type="ios-search" />筛选查询</div>
        <div class="search">
          <div class="input">
            <div class="text">请输入：</div>
            <Input
              v-model="goodsName"
              placeholder="请输入商品名称"
              clearable
              style="width: 200px"
            />
          </div>
          <div class="input">
            <div class="text">请输入：</div>
            <Input
              v-model="orderNum"
              placeholder="请输入订单号"
              clearable
              style="width: 200px"
            />
          </div>
          <div class="shuai" v-if="admintype == 1">
            <!-- <div class="selname" ></div> -->
            <div style="width: 120px; margin-top: 15px">选择商户：</div>
            <Select
              placeholder="请选择"
              class="sel"
              v-model="businessid"
              @on-change="changeMerchant(businessid)"
            >
              <Option
                :value="item.business_id"
                v-for="(item, index) in businessList"
                :key="index"
                >{{ item.business_name }}</Option
              >
            </Select>
          </div>
          <div class="shuai" v-if="admintype == 1 || admintype == 2">
            <!-- <div class="selname" ></div> -->
            <div style="width: 120px; margin-top: 15px">选择门店：</div>
            <Select placeholder="请选择" class="sel" v-model="storeid">
              <!-- <Option   value="">全部</Option> -->
              <Option
                :value="item.storeid"
                v-for="(item, index) in storeList"
                :key="index"
                >{{ item.s_name }}</Option
              >
            </Select>
          </div>
          <div class="shuai">
            <div style="width: 120px; margin-top: 15px">选择类型：</div>
            <Select placeholder="请选择" class="sel" v-model="ncid">
              <Option
                :value="item.id"
                v-for="(item, index) in typeList"
                :key="index"
                >{{ item.name }}</Option
              >
            </Select>
          </div>
          <div class="input">
            <Button type="success" @click="search()">搜索</Button>
          </div>
        </div>
      </div>
      <!-- 详情弹窗 -->
      <el-dialog title="详情" :visible.sync="dialogVisible" width="70%">
        <span>
          <table
            style="
              border-collapse: collapse;
              height: 250px;
              border: 1px solid gainsboro;
            "
          >
                
            <tr style="border-bottom: 1px solid gainsboro">
                 
              <td rowspan="5;" style="width: 500px">
                <tr>
                  <div
                    v-for="(item1, index1) in orderGoodsList"
                    :key="index1"
                    style="
                      width: 400px;
                      height: 100px;
                      margin-left: 40px;
                      display: flex;
                    "
                  >
                    <div style="width: 30%; height: 100%">
                      <img style="width: 100%; height: 100%" src="" />
                    </div>
                    <div>
                      <div>{{ item1.gname }}</div>
                      <div
                        style="
                          width: 100%;
                          margin-right: 250px;
                          margin-top: 35px;
                          display: flex;
                          justify-content: space-between;
                          padding: 4px;
                        "
                      >
                        <div>￥{{ item1.selling_price }}</div>
                        <div>x{{ item1.number }}</div>
                      </div>
                    </div>
                  </div>
                </tr>
                <!-- <tr>电话:</tr> -->
                <tr></tr>
              </td>
                    
              <td
                style="
                  width: 100px;
                  text-align: right;
                  background-color: #f5f5f5;
                "
              >
                商品名称：
              </td>
                    
              <td style="width: 300px">{{ orderDetail.ng_name }}</td>
                    
              <td style="width: 100px;  background-color: #f5f5f5">订单号：</td>
                    
              <td style="width: 300px">{{ orderDetail.norder_sn }}</td>
                  
            </tr>
                
            <tr style="border-bottom: 1px solid gainsboro">
                    <!-- <td  rowspan=3>111</td> -->
                    
              <td
                style="
                  width: 100px;
                  text-align: right;
                  background-color: #f5f5f5;
                "
              >
                商品类型：
              </td>
                    
              <td style="width: 300px">{{ orderDetail.class_name }}</td>
                    
              <td style="width: 100px;  background-color: #f5f5f5">
                支付来源：
              </td>
                    
              <td style="width: 300px">微信支付</td>
                  
            </tr>
                
            <tr style="border-bottom: 1px solid gainsboro">
                    <!-- <td  rowspan=3>111</td> -->
                    
              <td
                style="
                  width: 100px;
                  text-align: right;
                  background-color: #f5f5f5;
                "
              >
                商品图片：
              </td>
                    
              <td style="width: 300px">
                <img
                  style="width: 50%"
                  :src="imgUrl + orderDetail.img"
                  alt="图片走丢了"
                />
              </td>
                    
              <td style="width: 100px;  background-color: #f5f5f5">
                下单时间：
              </td>
                    
              <td style="width: 300px">{{ orderDetail.ctime }}</td>
                  
            </tr>
                
            <tr>
                    
              <td
                style="
                  width: 100px;
                  text-align: right;
                  background-color: #f5f5f5;
                "
              >
                商品数量：
              </td>
                    
              <td style="width: 300px">{{ orderDetail.number }}</td>
                    
              <td style="width: 100px;  background-color: #f5f5f5">
                商品价格：
              </td>
                    
              <td style="width: 300px">{{ orderDetail.selling_price }}</td>
                  
            </tr>
                
            <tr style="border-top: 1px solid gainsboro">
                    <!-- <td  rowspan=3>111</td> -->
                    
              <td
                style="
                  width: 100px;
                  text-align: right;
                  background-color: #f5f5f5;
                "
              ></td>
                    
              <td style="width: 300px"></td>
                    
              <td style="width: 100px;  background-color: #f5f5f5"></td>
                    
              <td style="width: 300px"></td>
                  
            </tr>
              
          </table>
        </span>
      </el-dialog>
      <!-- 表格 -->
      <el-table :data="tableData" border style="width: 80%; margin-left: 250px">
        <el-table-column prop="norder_sn" label="订单编号" width="180">
        </el-table-column>
        <el-table-column prop="ctime" label="提交时间" width="180">
        </el-table-column>
        <el-table-column prop="storeid" label="所属门店"> </el-table-column>
        <el-table-column prop="pay_fee" label="订单金额"> </el-table-column>
        <el-table-column prop="name" label="支付方式">微信支付</el-table-column>
        <el-table-column prop="is_status" label="订单状态">
          <template slot-scope="scope">
            <span>{{
              scope.row.is_status == 1
                ? "未使用"
                : scope.row.is_status == 2
                ? "使用中"
                : "已使用"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <span>
              <span
                @click="examine(scope.row.norder_id)"
                style="cursor: pointer"
                >查看</span
              >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[1, 5, 10, 15, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="titlePage"
      >
      </el-pagination>
    </frame>
  </div>
</template>
  
  <script>
import Frame from "../public/Frame.vue";

export default {
  name: "equityorder",
  props: {
    contentC: {
      type: Array,
    },
    titles: {
      type: Array,
    },
    flag: {
      type: Number,
    },
  },
  data() {
    return {
      top1: "13-4",
      top2: ["13"],
      social: [],
      dialogVisible: false,
      dialogVisibles: false,
      order_status: "",
      imgUrl: "",
      orderDetail: "",
      modal1: false,
      orderGoodsList: [],
      orderDetail: {},
      id: null,
      SHlist: [],
      state: null,
      form: {
        express_sn: "",
        express_name: "",
      },
      tabbar: ["全部订单", "未使用", "使用中", "已使用"],
      animal: "全部订单",
      businessList: [],
      storeList: [],

      set: "whole", //选择订单状态的id
      businessid: "",
      storeid: "",
      goodsName: "",
      orderNum: "",
      ncid: "",
      typeList: [
        {
          name: "充值",
          id: 1,
        },
        {
          name: "卡密",
          id: 2,
        },
        {
          name: "短链接",
          id: 3,
        },
      ],
      admintype: 0,
      tableData: [],
      pageSize: 10,
      titlePage: 0,
      page: 1,

      selectp: "",
      phoneName: "",
    };
  },
  created() {
    this.imgUrl = this.$request.imgUrl2() + "uploads/";
    // let admintype = this.$storage.getLocal("type");
    this.admintype = this.$storage.getLocal("type");
    if (this.admintype == 2) {
      this.changeMerchant("");
    }
    this.$request.businessList({}).then((res) => {
      console.log(res);
      this.businessList = res.data;
      // this.
    });
    this.$request
      .selNumOrderList({
        token: this.$storage.getLocal("token"),
        nid: "",
        is_status: "whole",
        ng_name: "",
        p_storeid: "",
        storeid: "",
        norder_sn: "",

        page: 1,
        limit: this.pageSize,
      })
      .then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.titlePage = res.data.count;
        }
      });
  },
  watch: {
    animal(newValue, oldValue) {
      this.cut(newValue);
    },
  },
  components: { Frame },
  methods: {
    // 选择不同状态下的订单
    cut(item) {
      let set = "";
      if (item == "全部订单") {
        set = "whole";
      } else if (item == "未使用") {
        set = 1;
      } else if (item == "使用中") {
        set = 2;
      } else if (item == "已使用") {
        set = 3;
      }
      this.set = set;
      this.$request
        .selNumOrderList({
          token: this.$storage.getLocal("token"),
          nid: this.ncid,
          is_status: this.set,
          ng_name: this.goodsName,
          p_storeid: this.businessid,
          storeid: this.storeid,
          norder_sn: this.orderNum,

          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data.data;
            this.titlePage = res.data.count;
          }
        });
    },
    //选中商户查询门店
    changeMerchant(businessid) {
      console.log(111);
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: businessid,
        })
        .then((res) => {
          console.log(res);
          this.storeList = res.data;
        });
    },
    close(orderid) {
      //删除
      this.$confirm("此操作将关闭该订单,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          let token = this.$storage.getLocal("token");
          this.$request
            .closeOrder({
              order_id: orderid,
              token,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$emit("close", "ok");
              } else {
                this.$emit("close", "ok");
                this.$notify({
                  title: "失败",
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消关闭",
          });
        });
    },
    //查看订单详情
    examine(id) {
      this.$request
        .selNumOrderInfo({
          token: this.$storage.getLocal("token"),
          norder_id: id,
        })
        .then((res) => {
          console.log(res);
          this.orderDetail = res.data;
          this.dialogVisible = true;

          // this.orderGoodsList = res.data.data;
        });
    },
    // 每页多少条
    handleSizeChange(val) {
      this.pageSize = val;
      this.$request
        .selNumOrderList({
          token: this.$storage.getLocal("token"),
          nid: this.ncid,
          is_status: this.set,
          ng_name: this.goodsName,
          p_storeid: this.businessid,
          storeid: this.storeid,
          norder_sn: this.orderNum,

          page: 1,
          limit: val,
        })
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data.data;
            this.titlePage = res.data.count;
          }
        });
    },
    // 跳转去某一页
    handleCurrentChange(val) {
      this.page = val;
      this.$request
        .selNumOrderList({
          token: this.$storage.getLocal("token"),
          nid: this.ncid,
          is_status: this.set,
          ng_name: this.goodsName,
          p_storeid: this.businessid,
          storeid: this.storeid,
          norder_sn: this.orderNum,

          page: val,
          limit: this.pageSize,
        })
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data.data;
            this.titlePage = res.data.count;
          }
        });
    },
    //搜索
    search() {
      this.$request
        .selNumOrderList({
          token: this.$storage.getLocal("token"),
          nid: this.ncid,
          is_status: this.set,
          ng_name: this.goodsName,
          p_storeid: this.businessid,
          storeid: this.storeid,
          norder_sn: this.orderNum,

          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data.data;
            this.titlePage = res.data.count;
          }
        });
    },
  },
};
</script>
  
  <style scoped="scoped">
img {
  width: 20px;
}
table {
  width: 100%;
  background: #eeeeee;
}

td {
  background-color: #ffffff;
  font-size: 15px;
  height: 30px;
  /* width: 15%; */
}

tr .titNa {
  background-color: #f9fafc;
  font-size: 15px;
  font-weight: 800;
}
.titNa {
  text-align: center;
}
.conBo {
  text-align: center;
}
.oper {
  display: flex;
  justify-content: space-around;
  color: #42b983;
}
.shenhe {
  color: #42b983;
}
.search {
  margin-left: 200px;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  /* border: 1px solid red; */
  /* justify-content: center; */
  /* text-align: left; */
}

.input {
  /* width: 200px; */
  /* margin: 0 auto; */
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  margin-left: 50px;
  padding: 10px 0 10px 0;
}

.liubai {
  width: 100%;
  height: 40px;
  background-color: #f3f3f3;
  font-size: 15px;
  text-align: left;
  padding-left: 220px;
  line-height: 40px;
}
.shuai {
  display: flex;
  margin-left: 50px;
  /* border:1px solid black */
}
.selname {
  width: 200px;
  border: 1px solid black;
}
.sel {
  /* margin-left: 10px; */
  margin-top: 10px;
}
</style>
  